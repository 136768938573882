<template>
  <div class="single-select">
    <multiselect
      aria-readonly="false"
      label="text"
      track-by="text"
      openDirection="below"
      v-model="valueSelected"
      :id="id"
      :placeholder="isOpen ? $t('SearchPlaceholderDefault') : placeholder"
      :class="[{ 'multiselect-is-invalid': state == false }, 'multiselect-component-single']"
      :disabled="disabled"
      :searchable="searchable"
      :close-on-select="!multiselect"
      :multiple="multiselect"
      :options="options"
      @open="
        () => {
          removeReadonly();
          emitOpen();
          isOpen = !isOpen;
        }
      "
      @close="
        () => {
          emitClose();
          isOpen = !isOpen;
        }
      "
      @input="
        () => {
          emitData();
        }
      "
    >
      <template
        slot="singleLabel"
        slot-scope="props"
      >
        {{
          translate
            ? $t(props.option.text)
            : props.option.nameEN
            ? verifyNameLang(props.option)
            : props.option.text
        }}
      </template>
      <template
        slot="option"
        slot-scope="props"
      >
        <div class="option-block">
          <div
            v-if="isIconString(props.option.icon)"
            :class="props.option.icon ? 'icon' : ''"
          >
            <div v-html="props.option.icon"></div>
          </div>
          <div v-else>
            <component :is="props.option.icon" />
          </div>
          <div
            class="option__desc d-flex justify-content-between"
            :class="{ 'footer-option': props.option.value == 'exit' }"
          >
            <div class="d-flex flex-column">
              <span :class="props.option.icon ? 'text-bold' : ''">
                {{
                  translate
                    ? $t(`${props.option.text}`)
                    : props.option.nameEN
                    ? verifyNameLang(props.option)
                    : props.option.text
                }}
              </span>
              <span class="description">
                {{ verifyDescriptionLang(props.option) }}
              </span>
            </div>

            <span v-show="valueSelected && props.option.text == valueSelected.text">
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4006_25886)">
                  <path
                    d="M12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2ZM12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM18 16.538L13.408 11.99L17.954 7.403L16.538 6L11.993 10.589L7.405 6.046L6 7.451L10.593 12.003L6.046 16.595L7.451 18L12.006 13.404L16.597 17.954L18 16.538Z"
                    fill="#4C4541"
                  />
                </g>
                <clipPath id="clip0_4006_25886">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  />
                </clipPath>
              </svg>
            </span>
          </div>
        </div>
      </template>

      <template
        v-if="showFooter"
        slot="afterList"
        class="d-flex justify-content-center"
      >
        <div class="separator"></div>
        <div
          @click="$emit('footerClick')"
          class="custom-footer"
        >
          <AddIcon class="icon" /><span class="text">{{ footerText }}</span>
        </div>
      </template>

      <template slot="noResult">
        <span class="multiselect-warning">{{ $t('Operator.NoResultsFound') }}</span>
      </template>

      <template slot="noOptions">
        <span class="multiselect-warning">
          {{ $t('EmptyList') }}
        </span>
      </template>
    </multiselect>

    <span
      class="multiselect-invalid-feedback"
      v-show="state == false"
    >
      <slot></slot>
    </span>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import AddIcon from '@core/assets/icons/add-icon.svg';
  import { props } from 'vue-prism-component';

  export default {
    components: {
      Multiselect,
      AddIcon
    },
    props: {
      id: {
        type: String
      },
      options: {
        type: Array,
        default: []
      },
      optionSelected: {
        type: Object | Array,
        default: null
      },
      placeholder: {
        type: String,
        default: 'Select an option'
      },
      multiselect: {
        type: Boolean,
        default: false
      },
      state: {
        type: Boolean,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      footerText: {
        type: String,
        default: ''
      },
      showFooter: {
        type: Boolean,
        default: false
      },
      translate: {
        type: Boolean,
        default: false
      },
      searchable: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      removeReadonly() {
        let nodes = document.querySelectorAll('.multiselect__input');
        for (let elem of nodes) {
          elem.removeAttribute('readonly');
        }
      },
      isIconString(icon) {
        return typeof icon === 'string' && icon.startsWith('<svg');
      },
      verifyNameLang(option) {
        const language = localStorage.getItem('language');
        return language == 'pt-BR' ? option.name : option.nameEN;
      },
      verifyDescriptionLang(option) {
        const language = localStorage.getItem('language');
        if (option.description) {
          return !option.descriptionEN || (option.descriptionEN && language == 'pt-BR')
            ? option.description
            : option.descriptionEN;
        }

        return '';
      },
      emitOpen() {
        this.$emit('open');
      },
      emitClose() {
        this.$emit('close');
      },
      emitData() {
        this.$emit('input', this.valueSelected);
      }
    },
    data() {
      return {
        isOpen: false,
        selectedComponentOption: null,
        valueSelected: this.optionSelected
      };
    },
    watch: {
      optionSelected(v) {
        this.valueSelected = v;
      }
    }
  };
</script>

<style lang="scss">
  .multiselect-component-single {
    min-height: 37.99px;
    height: 37.99px;

    &.multiselect--active {
      .multiselect__select {
        transform: none !important;
      }
    }

    &.multiselect--disabled {
      .multiselect__select {
        background-color: #eee !important;
      }
    }

    .multiselect__content-wrapper {
      box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
      margin: 5px 0;
      border-radius: 6px;
      max-height: 300px !important;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px !important; /* width of the entire scrollbar */
        border-radius: 50%;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #fbeee8;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #cfc4be; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 10px solid transparent;
      }

      svg {
        margin-left: 10px;
      }

      .multiselect__content {
        margin: 0 !important;

        .multiselect-warning {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #6e6b7b;
        }
        .multiselect__element {
          span {
            &::after {
              content: '' !important;
            }
          }

          .multiselect__option {
            padding: 12px 21px;

            color: #998f8a;

            .option__desc {
              width: 100%;
              white-space: normal;
              display: flex;
              align-items: center;
              span {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #998f8a;

                &.text-bold {
                  overflow: hidden;
                  color: #4c4541;
                  text-overflow: ellipsis;
                  font-weight: 600;
                }

                &.description {
                  overflow: hidden;
                  color: #998f8a;
                  text-overflow: ellipsis;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 16px;
                }
              }
            }

            &:active {
              background: #ffdbc4;

              .option__desc {
                span {
                  color: #974900;
                }
              }

              &.multiselect__option--highlight {
                background: #ffdbc4;
              }
            }

            &.multiselect__option--highlight {
              background: #fbeee8;

              .option-block {
                .icon svg {
                  fill: #974900;
                }
              }

              .option__desc {
                span {
                  color: #974900;
                }
              }
            }

            &.multiselect__option--selected {
              background: #974900;

              .option-block {
                .icon {
                  svg {
                    fill: #fff;
                  }
                }
              }

              &:active {
                .option-block {
                  .icon {
                    svg {
                      fill: #fff;
                    }
                  }
                }
                .option__desc {
                  span {
                    color: #974900;

                    svg {
                      path {
                        fill: #974900 !important;
                      }
                    }
                  }
                }
              }

              .option__desc {
                span {
                  color: #fff;

                  svg {
                    path {
                      fill: #fff !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .multiselect__select {
      border-radius: 5px;
      &::before {
        content: none !important;
      }

      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.47162 3.05602L5.00158 7.5909L0.528469 3.05593L1.17644 2.40774L4.73416 6.02906L5.00178 6.30146L5.26928 6.02894L8.82368 2.40785L9.47162 3.05602Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.75'/%3E%3C/svg%3E");
      background-repeat: no-repeat !important;
      background-position: center !important;
    }

    &.multiselect--disabled {
      .multiselect__current,
      .multiselect__select {
        background: transparent;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.47162 3.05602L5.00158 7.5909L0.528469 3.05593L1.17644 2.40774L4.73416 6.02906L5.00178 6.30146L5.26928 6.02894L8.82368 2.40785L9.47162 3.05602Z' fill='%234C4541' stroke='%234C4541' stroke-width='0.75'/%3E%3C/svg%3E") !important;
      }

      .multiselect__tags {
        border: 1px solid #7e7570;
        background: #eee;
      }

      .multiselect__placeholder {
        color: #7e7570 !important;
      }
    }

    &.multiselect--active {
      .multiselect__tags {
        border-color: #974900 !important;
      }
    }

    .multiselect__tags {
      min-height: 37.99px;
      height: 37.99px;

      border-radius: 6px !important;
      border-color: #d8d6de;

      .multiselect__single,
      .multiselect__input {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #6e6b7b;
      }

      .multiselect__single {
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .multiselect__placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 10px;
        color: #cfc4be;
        margin-bottom: 0;
        padding-left: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
      }
    }

    &.multiselect-is-invalid {
      border: 1px solid #d32f2f;
      border-radius: 6px;
      .multiselect__tags {
        border: none;
      }

      .multiselect__select {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.47162 3.05602L5.00158 7.5909L0.528469 3.05593L1.17644 2.40774L4.73416 6.02906L5.00178 6.30146L5.26928 6.02894L8.82368 2.40785L9.47162 3.05602Z' fill='%234C4541' stroke='%234C4541' stroke-width='0.75'/%3E%3C/svg%3E");
      }
    }
  }

  .option-block {
    display: flex;
    align-items: center;
    gap: 10px;

    .icon {
      svg {
        width: 24px;
        height: 24px;
        fill: #4c4541;
        margin: 0;
      }
    }
  }

  .separator {
    background-color: #cfc4be;
    width: 100%;
    height: 1px;
  }
  .custom-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 6px;
    .icon {
      display: flex;
      margin-left: 0 !important;
      width: 12px;
      height: 12px;
      fill: #974900;
    }

    .text {
      color: #974900;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.15px;
      line-height: 20px;
      padding: 4px 0;
    }

    border-radius: 5px;
    background-color: transparent !important;
    color: #974900 !important;
    margin: 8px 21px 16px;

    &:hover {
      background-color: #ffede2 !important;
    }

    &:active {
      background-color: #ffdbc4 !important;
    }
  }

  .multiselect-invalid-feedback {
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    color: #d32f2f;
  }

  .multiselect__element:has(.footer-option) {
    border-top: 1px solid #cfc4be !important;
    position: sticky;
    bottom: 0;
    background: #ffffff;
  }
</style>
